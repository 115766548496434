import React from 'react'
import styled from 'styled-components'

import { media } from '../../../util/breakpoints'



function WhyUse() {
  return (
    <Wrapper>
      <Container>
        <ContainerTitle>Por que eu devo utilizar o Hostli no meu imóvel?</ContainerTitle>
        <CardContainer>
          <Card>
            <CardImage src='/images/home/ico_clock.png' />
            <CardTitle>Economia de tempo</CardTitle>
            <CardText>Reduz o número de perguntas repetitivas e melhora a comunicação com os hóspedes.</CardText>
          </Card>
          <Card>
            <CardImage src='/images/home/ico_star.png' />
            <CardTitle>Melhores avaliações</CardTitle>
            <CardText>Informações claras e completas resultam em excelentes avaliações e fidelização.</CardText>
          </Card>
          <Card>
            <CardImage src='/images/home/ico_shield.png' />
            <CardTitle>Redução de Problemas</CardTitle>
            <CardText>Listando as regras e dicas de segurança, diminui-se a probabilidade de danos.</CardText>
          </Card>
        </CardContainer>
      </Container>
      <Container>
        <ContainerTitle>Por que os hóspedes amam os imóveis com Hostli?</ContainerTitle>
        <CardContainer>
          <Card>
            <CardImage src='/images/home/ico_target.png' />
            <CardTitle>Tudo em um só lugar</CardTitle>
            <CardText>Acesso rápido a informações sobre o imóvel, regras da casa, Wi-Fi, contatos de emergência, etc.</CardText>
          </Card>
          <Card>
            <CardImage src='/images/home/ico_pin.png' />
            <CardTitle>Experiências Locais</CardTitle>
            <CardText>Recomendações de restaurantes, atrações turísticas, transporte e eventos locais tornam a estadia mais agradável.</CardText>
          </Card>
          <Card>
            <CardImage src='/images/home/ico_mindfulness.png' />
            <CardTitle>Estadia sem stresse</CardTitle>
            <CardText>Orientações claras reduzem o estresse inicial de se adaptar ao local.</CardText>
          </Card>
        </CardContainer>
      </Container>
      <Container>
        <ContainerTitle style={{alignSelf: 'center', textAlign: 'center'}}>Hostli se tornou uma unanimidade</ContainerTitle>
        <CardContainer>
          <TestimonialCard>
            <TestimonialCardImage src='/images/home/testimonial-1.png' />
            <TestimonialCardTitle>Reduziu significativamente as interações e aumentou as avaliações. Pretendo utilizar nos meus outros imóveis também.</TestimonialCardTitle>
            <TestimonialCardText>Fernando Oliveira, anfitrião</TestimonialCardText>
          </TestimonialCard>
          <TestimonialCard>
            <TestimonialCardImage src='/images/home/testimonial-2.png' />
            <TestimonialCardTitle>Facilitou muito a minha vida! Nas minhas outras experiências, eu precisava enviar mensagens para o anfitrião o tempo todo.</TestimonialCardTitle>
            <TestimonialCardText>Renato Silva, hóspede</TestimonialCardText>
          </TestimonialCard>
        </CardContainer>
      </Container>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: 50px auto;
  padding: 20px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;


  ${media.tabMUp} {
    align-items: flex-start;
  }
`

const ContainerTitle = styled.h3`
  font-size: 22px;
  font-weight: bold;
  text-align: center;

  margin-bottom: 68px;

  ${media.tabMUp} {
    text-align: left;
    font-size: 48px;
    max-width: 650px;
    
  margin-bottom: 128px;
  }
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${media.tabMUp} {
    flex-direction: row;
  }
`

const Card = styled.div`
  background-color: #F6F6FF;
  border-radius: 24px;
  padding: 0 20px 48px;
  margin-bottom: 68px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;

  ${media.tabMUp} {
    align-items: center;
    margin-right: 18px;

    text-align: center;
    
    &:last-child {
      margin-right: 0;
    }
  }
`

const CardImage = styled.img`
  margin-top: -60px;
  max-width: 150px;
  width: 100%;

  ${media.tabMUp} {
    margin-top: -120px;
    max-width: 240px;
  }
`

const CardTitle = styled.p`
  font-size: 22px;
  font-weight: bold;
  margin: 0 0 12px;

  ${media.tabMUp} {
    font-size: 28px;
  }
`

const CardText = styled.p`
  font-size: 16px;

  ${media.tabMUp} {
    font-size: 22px;
  }
`

const TestimonialCard = styled(Card)`
  align-items: center;
  text-align: center;
`

const TestimonialCardTitle = styled(CardTitle)`
  font-size: 16px;
`

const TestimonialCardText = styled(CardText)`
font-size: 12px;
`

const TestimonialCardImage = styled(CardImage)`
  margin-top: -45px;
  margin-bottom: 24px;
  max-width: 90px;

  
  ${media.tabMUp} {
    margin-top: -75px;
    margin-bottom: 40px;
  }
`

export default WhyUse
