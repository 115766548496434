import { observable, action, computed } from 'mobx'

export enum Modal {
  SHARE_50OFF = 'SHARE_50OFF',
  QRCODE = 'QRCODE'
}

class ModalStore {
  @observable
  modals: {[key in Modal]: boolean} = {
    [Modal.SHARE_50OFF]: false,
    [Modal.QRCODE]: false
  }

  @computed
  get current () {
    return Object
      .keys(this.modals)
      .find(k => !!this.modals[k as Modal])
  }

  @action
  toggleModal(modal: Modal) {
    this.modals[modal] = !this.modals[modal]

    Object
      .keys(this.modals)
      .forEach(
        (k) => k !== modal && (this.modals[k as Modal] = false)
      )
  }

  @action
  closeAll() {
    Object
      .keys(this.modals)
      .forEach((k) => this.modals[k as Modal] = false)
  }
}

const storeInstance = new ModalStore()
export default storeInstance
