import React from 'react'

import CTABlock from './CTABlock'
import FeaturesBlock from './FeaturesBlock'
import HowToBlock from './HowToBlock'
import PossibilitiesBlock from './PossibilitiesBlock'
import Preview from './Preview'
import WhyUse from './WhyUse'

function Home() {
  return (
    <div>
      <CTABlock/>
      <FeaturesBlock/>
      <Preview/>
      <WhyUse/>
      <HowToBlock/>
      <PossibilitiesBlock/>
    </div>
  )
}

export default Home
