import React from 'react'
import styled from 'styled-components'
import { media } from '../../../util/breakpoints'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { roundButtonStyle } from '../../common/Button'
import { sendCreateAccountEvent } from '../../../shared/google-analytics'

function PossibilitiesBlock() {
  return (
    <Features>
      <Container>
        <Title>Hostli funciona pra todos os tipos de hospedagem</Title>
        <BlockContainer>
          <Block target="_blank" href="https://hostli.com.br/p/temporada">
            <BlockImg alt="Páginas do hostli.com.br" src="/images/home/alugueis.png"/>
            <BlockLink>Aluguéis de temporada <FontAwesomeIcon icon={faExternalLinkAlt}/></BlockLink>
          </Block>
          <Block target="_blank" href="https://hostli.com.br/p/hotel">
            <BlockImg alt="Páginas do hostli.com.br" src="/images/home/hoteis.png"/>
            <BlockLink>Hotéis <FontAwesomeIcon icon={faExternalLinkAlt}/></BlockLink>
          </Block>
          <Block target="_blank" href="https://hostli.com.br/p/pousada">
            <BlockImg alt="Páginas do hostli.com.br" src="/images/home/pousadas.png"/>
            <BlockLink>Pousadas <FontAwesomeIcon icon={faExternalLinkAlt}/></BlockLink>
          </Block>
          <Block target="_blank" href="https://hostli.com.br/p/hostel">
            <BlockImg alt="Páginas do hostli.com.br" src="/images/home/hostels.png"/>
            <BlockLink>Hostels <FontAwesomeIcon icon={faExternalLinkAlt}/></BlockLink>
          </Block>
        </BlockContainer>
        <CTALink onClick={() => sendCreateAccountEvent('possibilities block')} to="/criar-conta">Experimentar grátis</CTALink>
      </Container>
    </Features>
  )
}

const Features = styled.div`
  background-color: #1C3144;
  color: #fff;
  display: flex;
  justify-content: center;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 1200px;
  width: 100%;
  padding: 70px 0;

  text-align: left;

  ${media.upToTabM} {
    min-height: 65vh;
    padding: 70px 20px;
  }
`

const Block = styled.a`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  cursor: pointer;

  text-align: center;
  text-decoration: none;

  transition: .15s transform ease-in;

  ${media.upToTabM} {
    max-width: 100%;
  }


  &:hover {
    transform: translateY(-4px);
  }
`

const BlockContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 40px 0 90px;

  ${media.upToDeskL} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px 4px;
    margin-bottom: 48px;
  }
`

const BlockImg = styled.img`
  max-width: 100%;
  max-height: 400px;
  padding: 8px;
  margin-bottom: 18px;

  ${media.deskXL} {
    max-width: 280px;
  }
`

const BlockLink = styled.span`
  width: 100%;
  display: block;
  
  color: white;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  text-align: center;

  &:visited {
    color: white;
  }
`

const Title = styled.h2`
  margin: 0;
  width: 100%;
  padding-bottom: 40px;

  font-size: 32px;
  font-weight: 600;
  text-align: center;
  line-height: 52px;

  ${media.tabMUp} {
    font-size: 48px;
    max-width: 750px;
    padding-bottom: 90px;
  }
`

const CTALink = styled(Link)`
  ${roundButtonStyle}
  min-width: 280px;
`


export default PossibilitiesBlock
