import { isMobile } from "./is-mobile"


export const generateWhatsAppLink = (whatsAppNumber: string) => {
  if (!whatsAppNumber)
      return ''

  if (isMobile()) {
    return `whatsapp://send?phone=55${whatsAppNumber}`
  }

  return `https://wa.me/55${whatsAppNumber}`
}