import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {observer} from 'mobx-react'
import { Link, useHistory } from 'react-router-dom'

import { useStores } from '../../../stores/hooks'
import Button from '../../common/Button'
import GramInput from '../../common/GramInput'
import { validateEmail, validatePassword } from '../../../validation'

type LoginFormInputType = 'email' | 'password'

const validationFunctionMapping = {
  email: validateEmail,
  password: validatePassword
}

function Login() {
  const { accountStore } = useStores()
  const history = useHistory()

  const [credentials, setCredentials] = useState({
    email: '',
    password: ''
  })

  const [formErrors, setFormErrors] = useState({
    email: false,
    password: false,
  })

  const [isLoading, setIsLoading] = useState(false)
  const [hasCredentialError, setHasCredentialError] = useState(false)

  useEffect(() => {
    if (accountStore.isAuthenticated) {
      history.replace('/painel')
    }
  }, [accountStore, history])


  const validateInput = (inputName: LoginFormInputType, value: string) => {
    return {
      ...formErrors,
      [inputName]: validationFunctionMapping[inputName](value)
    }
  }

  const isFormValid = () => {
    const formFields: LoginFormInputType[] = ['email', 'password']

    return formFields.every(
      (field: LoginFormInputType) => (credentials[field].length && !formErrors[field])
    )
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value, name} = event.target
    setHasCredentialError(false)

    const errors = validateInput(name as LoginFormInputType, value)

    setFormErrors(errors)
    setCredentials({...credentials, [name]: value})
  }

  const redirect = () => {
    try {
      const defaultPath = '/painel'
      const urlParams = new URLSearchParams(window.location.search)
  
      history.replace(urlParams.get('redirect') || defaultPath)
    } catch (error) {
      history.replace('/painel')
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if(isFormValid()) {
      setIsLoading(true)

      accountStore.login(credentials)
        .then(() => redirect())
        .catch((err) => setHasCredentialError(true))
        .finally(() => setIsLoading(false))
      }
  }

  return (
    <Container>
      <Title>Para entrar no painel, faça o login</Title>
      <FormContainer>
        <form onSubmit={handleSubmit}>
          {hasCredentialError && <ErrorMessage>Email e/ou Senha inválidos</ErrorMessage>}
          <GramInput
            labelText="Email:"
            error={formErrors.email}
            onChange={handleOnChange}
            value={credentials.email}
            name="email"
            type="email"
          />
          <GramInput
            labelText="Senha:"
            error={formErrors.password}
            onChange={handleOnChange}
            value={credentials.password}
            name="password"
            type="password"
          />
          <ForgotPasswordContainer>
            <CustomLink to="/recuperar-senha">Esqueceu sua senha?</CustomLink>
          </ForgotPasswordContainer>
          <LoginButton isLoading={isLoading} type="submit">
            Entrar
          </LoginButton>
        </form>
      </FormContainer>
      <LoginText>Ainda não tem uma conta?<br /><CustomLink to="/criar-conta">Clique aqui e crie a sua conta grátis!</CustomLink></LoginText>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 55px);
  padding-bottom: 40px;
  background-color: #faf9f9;
`

const FormContainer = styled.div`
  box-sizing: border-box;
  width: 90%;
  max-width: 420px;
  margin: 36px auto 0;
  padding: 16px;

  border: 1px solid #dcdcdc;
  border-radius: 4px;

  background-color: #fff;

  box-shadow: 2px 2px 3px 0px #efefef;
`

const ErrorMessage = styled.p`
  margin: 4px 14px 14px;
  color: #ff6161;
`

const LoginButton = styled(Button)`
  width: 100%;
`

const Title = styled.h1`
  padding: 48px 0 12px;
  margin: 0;

  line-height: 42px;
  font-size: 36px;

  text-align: center;
  color: #3c3c3c;
`

const LoginText = styled.p`
  color: #3c3c3c;
  text-align: center;
  font-size: 18px;
  padding-bottom: 40px;
`

const CustomLink = styled(Link)`
  text-decoration: none;
  color: #2196f3;
`

const ForgotPasswordContainer = styled.div`
  text-align: right;
  margin: 0 0 15px;
`

export default observer(Login)
