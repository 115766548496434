import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { media } from '../../../util/breakpoints'
import { sendCreateAccountEvent } from '../../../shared/google-analytics'
import { roundButtonStyle } from '../../common/Button'

const handleCreateAccountClick = (_: React.MouseEvent) => {
  sendCreateAccountEvent('home cta banner')
}

function CTABlock() {
  return (
    <CTAWrapper>
      <CTAContainer>
        <Title>Otimize seu tempo e enriqueça a experiência do seu hóspede</Title>
        <SubTitle>Facilite a estadia do seu hóspede e seja bem avaliado fornecendo informações completas do imóvel, recomendações e dicas locais personalizadas</SubTitle>
        <CTAButton onClick={handleCreateAccountClick} to={`/criar-conta`}>Experimente grátis</CTAButton>
      </CTAContainer>
    </CTAWrapper>
  )
}

const CTAWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  
  min-height: 75vh;
  padding: 36px;
  
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.25)), url('/images/hero-background.png');
  

  text-align: left;
  color: #fff;
  
  ${media.tabMUp} {
    align-items: flex-start;
    justify-content: space-around;
    align-items: flex-start;
    background-position: center center;
    
    padding: 40px 120px;
    min-height: 85vh;
  }
`

const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${media.tabMUp} {
    max-width: 680px;
  }
`

const Title = styled.h1`
  margin: 0;
  padding: 26px 0;
  font-size: 32px;

  ${media.tabMUp} {
    font-size: 58px;
  }

  ${media.deskLUp} {
    font-size: 72px;
  }
`

const SubTitle = styled.h2`
  margin: 0;
  padding-bottom: 20px;
  font-weight: 400;
  font-size: 16px;

  ${media.tabMUp} {
    padding-bottom: 48px;
    font-size: 28px;
  }
`


const CTAButton = styled(Link)`
  ${roundButtonStyle};

  width: 240px;

  ${media.tabMUp} {
    font-size: 28px;
    width: 420px;
    padding: 16px;
  }

`

export default CTABlock
