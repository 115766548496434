import React, { InputHTMLAttributes } from 'react'
import styled from 'styled-components'

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  hasError?: boolean
}

function Input(props: IInputProps) {
  return (
    <StyledInput {...props}/>
  )
}

const StyledInput = styled.input<IInputProps>`
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  margin-top: 4px;
  padding: 4px 12px;

  border: 1px solid ${props => props.hasError ? '#ff6161' : '#D9D9D9'};
  border-radius: 8px;

  font-size: 16px;
  font-family: "Plus Jakarta Sans", sans-serif;

  outline: none;

  &[type="time"] {
    -webkit-appearance: none;
  }
`

export default Input
