import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStores } from '../../stores/hooks'
import { sendCreateAccountEvent } from '../../shared/google-analytics'

function LoggedUserLinks() {
  return (
    <>
      <MenuLink to="/painel">Painel</MenuLink>
      <MenuLink to="/painel/logout">Sair</MenuLink>
    </>
  )
}

function AnonymousUserLinks() {
  return (
    <>
      <HighlightedMenuLink onClick={() => sendCreateAccountEvent('header menu')} to="/criar-conta">Criar conta</HighlightedMenuLink>
      <MenuLink to="/entrar">Entrar</MenuLink>
    </>
  )
}

function Menu() {

  const {accountStore} = useStores()

  return (
    <>
      <MenuContainer>
        <MenuLinksWrapper>
          <MenuLogo to="/">Hostli</MenuLogo>
        </MenuLinksWrapper>
        <MenuLinksWrapper>
          {accountStore.isAuthenticated && <LoggedUserLinks/>}
          {!accountStore.isAuthenticated && <AnonymousUserLinks/>}
        </MenuLinksWrapper>
      </MenuContainer>
    </>
  )
}

const MenuContainer = styled.div`
  height: 54px;
  padding: 0 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #d8d8d8;
`

const MenuLinksWrapper = styled.div`
  display: flex;
  align-items: center;
`

interface IMenuLink {
  hideOnMobile?: boolean
}

//https://github.com/styled-components/styled-components/issues/135
const MenuLink = styled(({hideOnMobile: boolean, ...rest}) => <Link {...rest}/>)<IMenuLink>`
  padding: 8px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  color: #EF5D60;

  transition:
    color .4s cubic-bezier(.08,.59,.29,.99),
    border-color .4s cubic-bezier(.08,.59,.29,.99);

  &:last-of-type {
    padding-right: 0;
  }

  @media screen and (max-width: 520px) {
    display: ${props => props.hideOnMobile ? 'none' : 'block'}
  }
`

const MenuLogo = styled(MenuLink)`
  color: #EF5D60;
  font-weight: bold;
  font-size: 28px;
  padding-right: 16px;
  padding-left: 0;
`

const HighlightedMenuLink = styled(MenuLink)`
  border: 1px solid #EE5048;
  line-height: 16px;
  border-radius: 32px;
  padding: 8px 16px;
`

export default observer(Menu)
