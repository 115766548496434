import React from 'react'
import styled, {css} from 'styled-components'
import { media } from '../../util/breakpoints'

function Footer() {
  return (
    <Container>
      <Text>copyright © 2025 <HostliLogo href="https://www.hostli.com.br/">hostli.com.br</HostliLogo></Text>
      <LinksContainer>
        <Link href="/duvidas">dúvidas</Link>
        <Link href="mailto:contato@hostli.com.br">contato</Link>
        <Link href="/termos-de-uso">termos de uso</Link>
        <Link href="/privacidade">política de privacidade</Link>
      </LinksContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 24px;

  border-top: 1px solid #d8d8d8;
`

const HostliLogo = styled.a`
  color: #EF5D60;
  font-weight: 600;
  font-size: 20px;
  padding-right: 0;
  padding-left: 0;
  text-decoration: none;
`

const TextStyle = css`
  margin: 0;
  padding: 4px 8px;
  color: #212121;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  text-decoration: none;
`

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  ${media.tabMUp} {
    flex-direction: row;
  }
`

const Text = styled.p`
  ${TextStyle}
`

const Link = styled.a`
  ${TextStyle}
  text-align: right;

  transition: color .15s ease-in-out;

  :hover {
    color: #ff4342;
  }
`

export default Footer
