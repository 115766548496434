import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { media } from '../../../util/breakpoints'
import { sendCreateAccountEvent } from '../../../shared/google-analytics'
import { roundButtonStyle } from '../../common/Button'

function HowToBlock() {
  return (
    <Wrapper>
      <Container>
        <Title>É rápido e simples de criar o seu!</Title>
        <BlockContainer>
          <Block>
            <BlockImage src='/images/home/ico_1.png'/>
            <BlockTitle>Crie sua conta</BlockTitle>
            <BlockDescription>Crie sua conta e adicione os detalhes da sua propriedade</BlockDescription>
          </Block>
          <Block>
            <BlockImage src='/images/home/ico_2.png'/>
            <BlockTitle>Adicione conteúdo</BlockTitle>
            <BlockDescription>Personalize as informações de check-in, Wi-Fi, Regras da Casa e mais</BlockDescription>
          </Block>
          <Block>
            <BlockImage src='/images/home/ico_3.png'/>
            <BlockTitle>Compartilhe</BlockTitle>
            <BlockDescription>Envie para seus hóspedes antes ou durante a estadia para que tenham todas informações à mão</BlockDescription>
          </Block>
        </BlockContainer>
        <CTALink onClick={() => sendCreateAccountEvent('how-to banner')} to="/criar-conta">Experimentar grátis</CTALink>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  background-color: #1C3144;
  color: #fff;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  max-width: 1200px;
  margin: 0 auto;
  padding: 70px;

  text-align: center;

  ${media.upToTabM} {
    min-height: 65vh;
    padding: 70px 5%;
  }
`

const Block = styled.a`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 270px;

  cursor: pointer;

  ${media.upToTabM} {
    max-width: 100%;
    margin-bottom: 60px;
  }
`

const BlockContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 40px 0 90px;


  ${media.upToTabM} {
    flex-direction: column;
    margin-bottom: 0;
  }
`

const BlockImage = styled.img`
  ${media.upToDeskL} {
    max-width: 180px;
  }
`

const BlockTitle = styled.p`
  font-size: 42px;
  font-weight: 600;
  margin: 24px 0;

  ${media.upToTabM} {
    margin: 24px 0;
    font-size: 22px;
  }
  
`

const BlockDescription = styled.p`
  font-size: 26px;
  
  ${media.upToTabM} {
    font-size: 16px;
    margin: 0 0 24px;
  }
`

const Title = styled.h2`
  margin: 0;
  width: 100%;
  padding-bottom: 40px;

  font-size: 32px;
  font-weight: 600;
  text-align: center;
  line-height: 52px;

  ${media.tabMUp} {
    font-size: 48px;
  }
`

const CTALink = styled(Link)`
  ${roundButtonStyle}
  min-width: 280px;
`


export default HowToBlock
