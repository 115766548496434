export const breakpoints = {
  mobXS: 320,
  mobS: 360,
  mobM: 375,
  mobL: 425,
  mobXL: 525,
  tabM: 768,
  deskL: 1024,
  deskXL: 1366,
  deskXXL: 1600,
}

const screen = '@media screen and'

export const media = {
  mobXS: `${screen} (max-width: ${breakpoints.mobS - 1}px)`,
  mobS: `${screen} (min-width: ${
    breakpoints.mobS
  }px) and (max-width: ${breakpoints.mobM - 1}px)`,
  mobSUp: `${screen} (min-width: ${breakpoints.mobS}px)`,
  mobM: `${screen} (min-width: ${
    breakpoints.mobM
  }px) and (max-width: ${breakpoints.tabM - 1}px)`,
  mobMUp: `${screen} (min-width: ${breakpoints.mobM}px)`,
  upToMobL: `${screen} (max-width: ${breakpoints.mobL - 1}px)`,
  mobLUp: `${screen} (min-width: ${breakpoints.mobL}px)`,
  upToMobXL: `${screen} (max-width: ${breakpoints.mobXL - 1}px)`,
  upToTabM: `${screen} (max-width: ${breakpoints.tabM - 1}px)`,
  tabM: `${screen} (min-width: ${
    breakpoints.tabM
  }px) and (max-width: ${breakpoints.deskL - 1}px)`,
  tabMUp: `${screen} (min-width: ${breakpoints.tabM}px)`,
  upToDeskL: `${screen} (max-width: ${breakpoints.deskL - 1}px)`,
  deskL: `${screen} (min-width: ${
    breakpoints.deskL
  }px) and (max-width: ${breakpoints.deskXL - 1}px)`,
  deskLUp: `${screen} (min-width: ${breakpoints.deskL}px)`,
  deskXL: `${screen} (min-width: ${breakpoints.deskXL}px)`,
  deskXXL: `${screen} (min-width: ${breakpoints.deskXXL}px)`,
}
