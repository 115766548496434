import React from 'react'
import styled from 'styled-components'

import { media } from '../../../util/breakpoints'



function Preview() {
  return (
    <Wrapper>
      <Container>
        <Image src='/images/home/smartphone-preview.png' />
      </Container>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  background: linear-gradient(0deg, #B73E38, #EE5048);
`

const Image = styled.img`
  max-width: 80%;
  margin-top: -30px;

  ${media.deskLUp} {
    max-width: 400px;
  }

  ${media.deskXXL} {
    max-width: 100%;
  }
`

export default Preview
