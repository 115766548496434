import { css } from "styled-components";

export const RoundIconSyle = css`
  display: inline-block;
  width: 34px;
  height: 34px;
  padding: 6px;
  margin-left: 6px;
  border-radius: 32px;
  text-align: center;

  cursor: pointer;

  background-color: #FCDCDA;

  transition: background-color ease 0.3s;

  &:hover {
    background-color: #FDCFCC;
  }
`