import React from 'react'
import styled from 'styled-components'

import { media } from '../../../util/breakpoints'
import { Link } from 'react-router-dom'
import { roundButtonStyle } from '../../common/Button'
import { sendCreateAccountEvent } from '../../../shared/google-analytics'

function FeaturesBlock() {

  
  const handleCreateAccountClick = (_: React.MouseEvent) => {
    sendCreateAccountEvent('features block cta')
  }

  return (
    <Features>
      <Title>O que é o Hostli?</Title>
      <SubTitle>Um guia completo para seu hópede e mais segurança e facilidade para você</SubTitle>
      <Wrapper>
        <Block className='first-block'>
          <BlockHeader>
            <BlockTitle>Informações completas do imóvel</BlockTitle>
            <BlockIcon src="/images/home/ico_target.png"/>
          </BlockHeader>
          <BlockImage src="/images/home/general-info.png"/>
        </Block>
        <Block className='second-block'>
          <BlockHeader>
            <BlockTitle>Horários de check-in e checkout</BlockTitle>
            <BlockIcon src="/images/home/ico_clock.png"/>
          </BlockHeader>
          <BlockImage src="/images/home/checkin-checkout.png"/>
        </Block>
      </Wrapper>
      <Wrapper>
        <Block className='third-block'>
          <BlockHeader>
            <BlockTitle>Sua casa, suas regras</BlockTitle>
            <BlockIcon src="/images/home/ico_shield.png"/>
          </BlockHeader>
          <BlockImage src="/images/home/rules.png"/>
        </Block>
        <Block className='fourth-block'>
          <BlockHeader>
            <BlockTitle>Recomendações e dicas locais</BlockTitle>
            <BlockIcon src="/images/home/ico_pin.png"/>
          </BlockHeader>
          <BlockImage src="/images/home/recommendations.png"/>
        </Block>
      </Wrapper>
      <CTAButton onClick={handleCreateAccountClick} to={`/criar-conta`}>Experimente grátis</CTAButton>
    </Features>
  )
}

const Features = styled.div`
  padding: 38px 18px 64px;
  max-width: 1200px;
  margin: 0 auto;
`

const Title = styled.p`
  margin: 0;
  width: 100%;

  color: #212121;
  font-size: 32px;
  font-weight: bold;
  text-align: center;

  ${media.tabMUp} {
    font-size: 72px;
  }
`

const SubTitle = styled.p`
  color: #212121;
  font-size: 16px;
  text-align: center;
  margin-bottom: 24px;

  ${media.tabMUp} {
    font-size: 32px;
  }
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  
  ${media.tabMUp} {
    flex-direction: row;
  }
`

const Block = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 20px;
  margin-bottom: 16px;

  background-color: #F6F6FF;
  border-radius: 18px;

  ${media.tabMUp} {
    max-width: 50%;

    &:first-child {
      margin-right: 16px;
    }

    &.first-block {
      flex-direction: row;
      justify-content: space-between;
      max-width: 60%;

      > div {
        flex-direction: column;
        width: auto;
      }

      > img {
        align-self: center;
        width: 340px;
      }
    }

    &.second-block {
      max-width: 40%;

    }

    &.third-block {
      max-width: 40%;
    }

    &.fourth-block {
      flex-direction: row;
      max-width: 60%;

      > div {
        flex-direction: column;
      }

      > img {
        max-width: inherit;
        align-self: flex-end;
      }
    }

  }
`

const BlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const BlockTitle = styled.p`
  margin: 0;
  width: 160px;

  font-weight: bold;
  font-size: 16px;

  ${media.tabMUp} {
    font-size: 18px;
    width: 150px;
  }

  ${media.deskLUp} {
    font-size: 32px;
    width: 220px;
  }
`

const BlockImage = styled.img`
  max-width: 100%;
  max-height: 400px;
  align-self: end;
  margin-bottom: -20px;
`
const BlockIcon = styled.img`
  width: 80px;
  height: 80px;
`

const CTAButton = styled(Link)`
  ${roundButtonStyle};

  width: 240px;
  display: block;
  margin: 38px auto 0;

  ${media.tabMUp} {
    font-size: 18px;
    width: 360px;
    padding: 12px;
  }
`

export default FeaturesBlock
