import React, { useState } from 'react'

import { faClone } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { RoundIconSyle } from '../styles';

interface IProps {
  text: string
}

export const CopyIcon = ({text}: IProps) => {

  const [stateIcon, setStateIcon] = useState(faClone)
  const {addToast} = useToasts()


  const handleCopy = () => {
    navigator.clipboard.writeText(text).then(
      () => {
        setStateIcon(faCheck)
        addToast('Copiado!')
      },
      () => {
        setStateIcon(faTimes)
        addToast('Não consegui copiar! :(')
      }
    );
  };

  return <>
    <ToastProvider>
      <RoundIcon onClick={handleCopy}>
        <FontAwesomeIcon icon={stateIcon} color='#EE5048'/>
      </RoundIcon>
    </ToastProvider>
  </>
}


const RoundIcon = styled.span`
  ${RoundIconSyle}
`