import React, { ButtonHTMLAttributes, RefObject } from 'react'
import styled, {css} from 'styled-components'
import LoadingIcon from '../LoadingIcon'

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean
  buttonRef?: RefObject<HTMLButtonElement>
}

function Button({isLoading, buttonRef, ...rest}: IButtonProps) {
  return (
    <StyledButton ref={buttonRef ?? null} disabled={!!isLoading} {...rest}>
      {!isLoading && rest.children}
      {isLoading && (<LoadingIcon/>)}
    </StyledButton>
  )
}

export const buttonStyle = css`
display: flex;
align-items: center;
justify-content: center;

box-sizing: border-box;
height: 40px;
width: 100%;
padding: 4px 12px;

background-color: #0887ef;
border-radius: 4px;
border: none;
outline: none;

color: white;
font-weight: 700;
font-size: 18px;
font-family: "Plus Jakarta Sans", sans-serif;
text-decoration: none;

cursor: pointer;

transition: background-color 250ms ease;

&[disabled] {
  opacity: 0.7;
}

&:hover {
  background-color: #0a5c9e;
}`

export const roundButtonStyle = css`
  border: none;
  min-width: 200px;
  padding: 12px;
  margin-bottom: 12px;
  text-decoration: none;

  cursor: pointer;

  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;

  border-radius: 50px;

  background: linear-gradient(90deg, #EE5048, #FF691E);

  :disabled {
    background: linear-gradient(90deg, #d1d1d1, #a1a1a1);
  }
`

const StyledButton = styled.button`
  ${roundButtonStyle}
`

export default Button
