import React from 'react'
import styled from 'styled-components'
import { media } from '../../util/breakpoints'


function accordionAnimation(e: React.MouseEvent) {
  const el = e.currentTarget
  const titleElement = el.querySelector('p:first-of-type')
  if (titleElement) {
    console.log(titleElement.classList)
    el.classList.toggle("active")
  }
}

function FAQ() {

  return (
    <>
      <HeaderWrapper>
        <Title>Dúvidas?</Title>
      </HeaderWrapper>
      <BodyWrapper>
        <SubTitle>
          Sobre o Hostli
        </SubTitle>
        <div>
          <QuestionWrapper>
            <Question>O que é o Hostli?</Question>
            <Answer>
              O Hostli é uma plataforma que permite criar um guia digital para sua propriedade. Com ele, você pode compartilhar informações importantes, como horários de check-in e checkout, Wi-Fi, recomendações de lugares para visitar e regras da casa, oferecendo uma experiência mais profissional e prática para seus hóspedes.
            </Answer>
          </QuestionWrapper>
          <QuestionWrapper>
            <Question>Para quem o Hostli é indicado?</Question>
            <Answer>
              O Hostli é ideal para proprietários de imóveis para aluguel de temporada, pousadas e pequenos hotéis que desejam melhorar a comunicação com seus hóspedes e oferecer uma estadia mais tranquila e organizada.
            </Answer>
          </QuestionWrapper>
          <QuestionWrapper>
            <Question>Os hóspedes precisam baixar algum aplicativo para acessar as informações?</Question>
            <Answer>
              Não. Todas as informações ficam disponíveis em uma página web personalizada, que pode ser acessada pelo navegador de qualquer dispositivo, apenas clicando no link.
            </Answer>
          </QuestionWrapper>
          <QuestionWrapper>
            <Question>Como eu compartilho minha página do Hostli com os hóspedes?</Question>
            <Answer>
              Assim que criar a sua conta e configurar a sua página, você verá no cabeçalho do painel o link para sua página. Você pode enviar esse link diretamente para seus hóspedes via WhatsApp, mensagem ou e-mail.
            </Answer>
          </QuestionWrapper>
          <QuestionWrapper>
            <Question>Posso usar o Hostli para mais de uma propriedade?</Question>
            <Answer>
            Durante a fase inicial, o Hostli suporta apenas uma propriedade por conta. No futuro, planejamos incluir suporte para múltiplas propriedades.
            </Answer>
          </QuestionWrapper>
          <QuestionWrapper>
            <Question>Como eu crio uma conta?</Question>
            <Answer>
              Para criar uma conta basta você entrar na página <a href="/criar-conta">Criar Conta</a> e se registrar com o nome da sua propriedade, email válido e senha. É super simples!
            </Answer>
          </QuestionWrapper>
          <QuestionWrapper>
            <Question>Quanto custa usar o Hostli?</Question>
            <Answer>
              Ao criar a conta você recebe 30 dias de teste grátis, após isso, o custo da assinatura é de R$8,90/mês.
            </Answer>
          </QuestionWrapper>
          <QuestionWrapper>
            <Question>O meio de pagamento da assinatura é seguro?</Question>
            <Answer>
              Muito! Nós utilizamos o Stripe para gerenciar os pagamentos. Todos pagamentos são feitos dentro do sistema do Stripe e nós não guardamos nenhuma informação sobre seu cartão de crédito. Saiba mais sobre o Stripe e seus termos de uso em <a href="https://stripe.com/br">https://stripe.com/</a>.
            </Answer>
          </QuestionWrapper>
          <QuestionWrapper>
            <Question>Como excluo minha conta?</Question>
            <Answer>
              Você pode excluir sua conta através do painel, sob a seção "conta". Caso você tenha uma assinatura ativa, você precisará cancelar ela antes de excluir sua conta.
            </Answer>
          </QuestionWrapper>
          <QuestionWrapper>
            <Question>Como cancelo o Premium?</Question>
            <Answer>
              Você pode cancelar a assinatura Premium pelo nosso painel. Esses são os passos:
              <ul>
                <li>Entrar no painel com seu email e senha</li>
                <li>No fim da página, na sessão "conta", clique em "Gerenciar assinatura"</li>
                <li>No painel do Stripe, selecione o plano de assinatura</li>
                <li>Cancele a assinatura</li>
                <li><b>Bônus:</b> Que tal nos dizer o motivo do cancelamento? Você pode fazer isso clicando <a href="mailto:contato@hostli.com.br?subject=Feedback%20da%20assinatura%20Premium">aqui</a>.</li>
              </ul>
            </Answer>
          </QuestionWrapper>
          {/* <QuestionWrapper>
            <Question>Como altero minha URL (usuário)?</Question>
            <Answer>
              Para alterar sua URL (seu usuário), é necessário nos enviar um email, a partir do email utilizado no cadastro. Você pode fazer isso clicando <a href="mailto:contato@hostli.com.br?subject=Quero%20alterar%20minha%20URL">aqui</a>. É importante ressaltar que uma vês que o seu usuário é alterado, a URL antiga deixa de funcionar e fica disponível para outros usuários.
            </Answer>
          </QuestionWrapper> */}
        </div>
      </BodyWrapper>
    </>
  )
}

const HeaderWrapper = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  background-color: #EF5D60;

  ${media.upToTabM} {
    height: 240px;
  }
`

const BodyWrapper = styled.div`
  max-width: 900px;
  padding: 24px;
  margin: 0 auto;
  color: #212121;
`

const Title = styled.h1`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 24px;

  color: #fff;
  font-size: 42px;
`

const SubTitle = styled.h2`
  margin: 48px 0;
  font-size: 34px;
`

const QuestionWrapper = styled.div.attrs({
  onClick: accordionAnimation
})`
  margin: 20px 0;
  padding: 24px;

  font-size: 28px;

  border: 1px solid #e6e6e6;
  border-radius: 4px;
  box-shadow: 2px 2px 4px 0px rgb(0, 0, 0, 0.1);

  &:last-of-type {
    margin-bottom: 86px;
  }

  &.active {
    p {
      display: block;
    }
  }
`

const Question = styled.p`
  margin: 12px 0;
  font-size: 26px;
  font-weight: bold;
  cursor: pointer;
`

const Answer = styled.p`
  display: none;
  font-size: 20px;
`


export default FAQ
