import React, { useRef } from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'

import { useStores } from '../../../../stores/hooks'
import { QRCodeCanvas } from 'qrcode.react'

function QrCodeModal() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { accountStore } = useStores()

  const downloadStringAsFile = (data: string, filename: string) => {
    let a = document.createElement('a')
    a.download = filename
    a.href = data
    a.click()
  }

  const download = () => {
    const node = canvasRef.current
    if (node == null) {
      return
    }

    const dataURI = node.toDataURL('image/png')

    downloadStringAsFile(dataURI, 'hostli-qrcode.png')
  }


  return (
    <ModalContainer onClick={(e) => e.stopPropagation()}>
      <Title>Compartilhe seu QR Code!</Title>
      <Text>Use o QR Code para facilitar o acesso aos seus hóspedes</Text>
      <QRCodeCanvas
        ref={canvasRef}
        size={300}
        value={accountStore.placePageUrl + '?utm_source=qrcode'}
        marginSize={4}
        title='qrcode'
      />
      <CouponWrapper>
        <CopyButton onClick={download}>Baixar</CopyButton>
      </CouponWrapper>
    </ModalContainer>
  )

}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  padding: 24px;
  margin: 0 8px;

  border-radius: 6px;

  background-color: #fff;
  color: #212121;

  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.05),
              2px 4px 4px rgba(0, 0, 0, 0.05),
              4px 8px 8px rgba(0, 0, 0, 0.05),
              8px 16px 16px rgba(0, 0, 0, 0.05),
              16px 32px 32px rgba(0, 0, 0, 0.05);
`

const Title = styled.p`
  font-size: 24px;
  font-weight: 600;
`

const Text = styled.p`
  width: 100%;
  margin: 4px;
`

const CouponWrapper = styled.div`
  display: flex;
  justify-items: center;
  margin: 0;
  font-size: 24px;
`

const CopyButton = styled.span`
  margin-left: 12px;
  padding: 6px 6px;

  font-size: 16px;
  font-weight: 400;
  color: #676767;
  line-height: 14px;

  border: 1px solid #676767;
  border-radius: 4px;

  cursor: pointer;
`


export default observer(QrCodeModal)
