import React, { HTMLAttributes, lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { ToastProvider } from 'react-toast-notifications'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  RouteProps
} from 'react-router-dom'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import './index.css'
import * as serviceWorker from './serviceWorker'
import Home from './components/Home'
import FAQ from './components/FAQ'
import PlacePage from './components/PlacePage'
import Menu from './components/Menu'
import Login from './components/Admin/Login'
import ForgotPassword from './components/Admin/ForgotPassword'
import ResetPassword from './components/Admin/ResetPassword'
import Register from './components/Admin/Register'
import Footer from './components/Footer'
import Terms from './components/Terms'
import Privacy from './components/Privacy'
import Helmet from 'react-helmet'
import LoadingWrapper from './components/common/LoadingWrapper'
import ApplicationModal from './components/common/ApplicationModal';

const Admin = lazy(() => import('./components/Admin'))


Sentry.init({
  dsn: "https://7bd1490c625a4b29bd6767a276aaefd0@o1027427.ingest.sentry.io/6002373",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const RouteWithMenu = (props: RouteProps & HTMLAttributes<HTMLDivElement>) => 
  <>
    <Menu/>
    <Route path={props.path}>
      {props.children}
    </Route>
    <Footer/>
  </>

ReactDOM.render(
  <>
    <React.StrictMode>
      <Router>
        <ApplicationModal/>
        <Switch>
          <RouteWithMenu path="/criar-conta">
            <Helmet>
              <title>Crie sua conta! - Hostli: A Solução para Simplificar a Comunicação com seus Hóspedes</title>
            </Helmet>
            <Register/>
          </RouteWithMenu>
          <RouteWithMenu path="/entrar">
            <Helmet>
              <title>Login - Hostli: A Solução para Simplificar a Comunicação com seus Hóspedes</title>
            </Helmet>
            <Login/>
          </RouteWithMenu>
          <RouteWithMenu path="/recuperar-senha">
            <ToastProvider>
              <ForgotPassword/>
            </ToastProvider>
          </RouteWithMenu>
          <RouteWithMenu path="/redefinir-senha">
            <ToastProvider>
              <ResetPassword/>
            </ToastProvider>
          </RouteWithMenu>
          <Route path="/painel">
            <Helmet>
              <title>Painel - Hostli</title>
            </Helmet>
            <Suspense fallback={<LoadingWrapper isLoading={true}/>}>
              <Admin/>
            </Suspense>
          </Route>
          <RouteWithMenu path="/termos-de-uso">
            <Helmet>
              <title>Termos de uso - Hostli: A Solução para Simplificar a Comunicação com seus Hóspedes</title>
            </Helmet>
            <Terms/>
          </RouteWithMenu>
          <RouteWithMenu path="/privacidade">
            <Helmet>
              <title>Política de privacidade - Hostli: A Solução para Simplificar a Comunicação com seus Hóspedes</title>
            </Helmet>
            <Privacy/>
          </RouteWithMenu>
          <RouteWithMenu path="/duvidas">
            <Helmet>
              <title>Dúvidas - Hostli: A Solução para Simplificar a Comunicação com seus Hóspedes</title>
            </Helmet>
            <FAQ/>
          </RouteWithMenu>
          <Route path="/p/:pageId">
            <Helmet>
              <title>Hostli | Bem-vindo</title>
              <meta name="robots" content="noindex"/>
            </Helmet>
            <ToastProvider autoDismiss={true}>
              <PlacePage/>
            </ToastProvider>
          </Route>
          <RouteWithMenu path="/">
            <Home/>
          </RouteWithMenu>
        </Switch>
      </Router>
    </React.StrictMode>
  </>,
  document.getElementById('root')
)

serviceWorker.unregister()
