
import { IRecommendation, IRecommendationSuggestion } from '../shared/places/interfaces'
import { BaseService } from './BaseService'

interface ICreateRecommendationRequest {
  data: IRecommendation
}

interface IUpdateRecommendationOrderRequest {
  data: number[]
}

interface IUpdateRecommendationOrderDTO {
  [id: string]: number
}

interface IRecommendationsSuggestionsResponse {
  data: IRecommendationSuggestion[]
}

class RecommendationService extends BaseService {

  async createRecommendation(data: IRecommendation): Promise<ICreateRecommendationRequest> {
    return this.post('/recommendation', data)
  }

  async updateRecommendation(id: number, data: IRecommendation): Promise<ICreateRecommendationRequest> {
    return this.put(`/recommendation/${id}`, data)
  }

  async deleteRecommendation(id: number): Promise<IUpdateRecommendationOrderRequest> {
    return this.delete(`/recommendation/${id}`)
  }

  async updateRecommendationOrder(data: IUpdateRecommendationOrderDTO): Promise<IUpdateRecommendationOrderRequest> {
    return this.post(`/recommendation/update-order`, data)
  }

  async getRecommendationsSuggestions(include: string[] = [], exclude: string[] = []): Promise<IRecommendationsSuggestionsResponse> {
    return this.get('/recommendation/search', {
      params: {
        include,
        exclude
      }
    })
  }
}

export default new RecommendationService()
