import { IAddress } from "../shared/places/interfaces"

export function formatAddress(address?: IAddress) {
  if (!address) return ''

  const { 
    street,
    number,
    neighborhood,
    city,
    state,
    postalCode,
    complement,
   } = address

  let firstPart = []
  let secondPart = []

  if (street) {
    firstPart.push(street)
  }

  if (number) {
    firstPart.push(number)
  }

  if (complement) {
    firstPart.push(complement)
  }

  if (neighborhood) {
    firstPart.push(neighborhood)
  }

  if (city) {
    firstPart.push(city)
  }

  secondPart.push(firstPart.join(', '))

  if (state) {
    secondPart.push(state)
  }

  if (postalCode) {
    secondPart.push(postalCode)
  }


  return secondPart.join(' - ')
}