import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import {observer} from 'mobx-react'

import { useStores } from '../../../stores/hooks'
import Button from '../../common/Button'
import GramInput from '../../common/GramInput'
import {
  validateUsername,
  validateEmail,
  validateName,
  validatePassword
} from '../../../validation'
import { sendAccountCreatedEvent } from '../../../shared/google-analytics'

type RegisterFormInputType = 'username' | 'name' | 'email' | 'password'

type TFormValues = {
  [key in RegisterFormInputType]: string
}

type TValidationError = {
  [key in RegisterFormInputType]: string
}

const validationFunctionMapping = {
  username: validateUsername,
  name: validateName,
  email: validateEmail,
  password: validatePassword
}

function Register() {
  const {accountStore} = useStores()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (accountStore.isAuthenticated) {
      history.replace('/painel')
    }

    const queryParams = new URLSearchParams(location.search)
    const username = queryParams.get('username')

    if (username) {
      const errors = validateInput('username', username!)

      setFormErrors(errors)
      setAccount({...account, username})
    }

  }, [accountStore, history, location])  // eslint-disable-line react-hooks/exhaustive-deps

  const [account, setAccount] = useState({} as TFormValues)
  const [formErrors, setFormErrors] = useState({} as TValidationError)
  const [isLoading, setIsLoading] = useState(false)

  const validateInput = (inputName: RegisterFormInputType, value: string) => {
    return {
      ...formErrors,
      [inputName]: validationFunctionMapping[inputName](value)
    }
  }

  const isFormValid = () => {
    const formFields: RegisterFormInputType[] = ['name', 'email', 'password']

    return formFields.every(
      (field: RegisterFormInputType) => (account[field]?.length && !formErrors[field])
    )
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value, name} = event.target

    const errors = validateInput(name as RegisterFormInputType, value)

    setFormErrors(errors)
    setAccount({...account, [name]: value})
  }

  const handleServerValidationError = (e: any) => {
    if (!e.data?.errors) {
      throw e
    }

    const errors = e.data.errors
    for (const field in errors) {
      setFormErrors({
        ...formErrors,
        [field]: errors[field]
      })
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if(isFormValid()) {
      setIsLoading(true)
      accountStore.createAccount({...account, account_type: 'free'})
        .then(() => sendAccountCreatedEvent())
        .then(() => history.replace('/painel/onboarding'))
        .catch(handleServerValidationError)
        .finally(() => setIsLoading(false))
    }
  }

  return (
    <Container>
      <Title>Crie sua conta</Title>
      <SubTitle>É grátis!</SubTitle>
      <FormContainer>
        <form onSubmit={handleSubmit}>
          <GramInput
            labelText="Nome da sua Propriedade:"
            error={formErrors.name}
            onChange={handleOnChange}
            value={account.name}
            name="name"
          />
          <GramInput
            labelText="Email:"
            error={formErrors.email}
            onChange={handleOnChange}
            value={account.email}
            name="email"
            type="email"
          />
          <GramInput
            labelText="Senha:"
            subText="pelo menos 8 caracteres, tá bom?"
            error={formErrors.password}
            onChange={handleOnChange}
            value={account.password}
            name="password"
            type="password"
          />
          <Agreement>Ao clicar em Cadastrar, você concorda com nossos <a href="/termos-de-uso">Termos</a> e <a href="/privacidade">Política de Privacidade</a>.</Agreement>
          <RegisterButton onClick={() => isFormValid()} isLoading={isLoading} type="submit">
            Cadastrar
          </RegisterButton>
        </form>
      </FormContainer>
      <LoginText>Já tem uma conta? <LoginLink to="/entrar">Faça seu login.</LoginLink></LoginText>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 55px);
  background-color: #faf9f9;
`

const FormContainer = styled.div`
  box-sizing: border-box;
  width: 90%;
  max-width: 420px;
  margin: 18px auto 0;
  padding: 16px;

  border: 1px solid #dcdcdc;
  border-radius: 4px;

  background-color: #fff;

  box-shadow: 2px 2px 3px 0px #efefef;
`

const RegisterButton = styled(Button)`
`

const Title = styled.h1`
  padding: 48px 0 12px;
  margin: 0;

  line-height: 52px;
  font-size: 48px;

  text-align: center;
  color: #3c3c3c;
`

const SubTitle = styled.h2`
  padding: 12px 0;
  margin: 0;

  font-size: 32px;
  line-height: 36px;

  text-align: center;
  color: #0E9594;
`

const LoginText = styled.p`
  color: #3c3c3c;
  text-align: center;
  font-size: 18px;
  padding-bottom: 40px;
`

const LoginLink = styled(Link)`
  text-decoration: none;
  color: #2196f3;
`

const Agreement = styled.p`
  color: #3c3c3c;
  margin: -8px 0 16px;
  font-size: 14px;
`

export default observer(Register)
